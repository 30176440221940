// Gatsby requirements
import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"

// Storyblok preview
import { storyblokEditable, useStoryblokState } from "gatsby-source-storyblok"

// Components
import Layout from '../components/Layout/Layout'
import Seo from '../components/Layout/Seo'
import Image from "utils/Image"
import RichText from "utils/RichTextRenderer"
import FeaturedPosts from "components/FeaturedPosts/FeaturedPosts"

// Styles
import * as CSS from "styles/BlogPost.module.scss"


const BlogPost = ({ data }) => {

    const [previewStory, setPreviewStory] = useState(null);

    let story = useStoryblokState(data.storyblokEntry);

    useEffect(() => {
        if (window.location.search.includes('_storyblok')) {
            setPreviewStory(null);
        }
    }, [story]);

    useEffect(() => {
        if (window.location.search.includes('_storyblok')) {
            const url = `https://api.storyblok.com/v2/cdn/stories/${data.storyblokEntry.full_slug}?version=draft&token=${process.env.GATSBY_STORYBLOK_ACCESS_TOKEN}&cv=${Date.now()}`;
        
            const fetchData = async () => {
                try {
                    const response = await fetch(url);
                    const json = await response.json();
                    setPreviewStory(json.story);
                } catch (error) {
                    console.log("error", error);
                }
            };
        
            fetchData();
        }
    }, []);

    const content = previewStory?.content || story?.content;

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const releaseDate = content?.releaseDate ? new Date(content.releaseDate) : null;
    const updateDate = content?.updateDate ? new Date(content.updateDate) : null;


    return (
        <Layout footerData={data.footer} noFooterMargin headerData={data.header}>
            <main className={`wrapper ${CSS.article}`} id="content" {...storyblokEditable(content)}>
                <article>
                    
                    <h1>{content.title}</h1>
                    <div className={CSS.meta}>
                        {releaseDate && <span><img src="/icons/calendar-month.svg" alt="" aria-hidden="true" loading="eager" width="16" height="16" /> Published: {months[releaseDate.getMonth()] + ' ' + releaseDate.getDate() + ', ' + releaseDate.getFullYear()}</span> }
                        {updateDate && <span> • </span> }
                        {updateDate && <span>Updated: {months[updateDate.getMonth()] + ' ' + updateDate.getDate() + ', ' + updateDate.getFullYear()}</span> }
                    </div>
                    <Image className={CSS.thumbnail} img={content.thumbnail} loading="eager" />
                    <RichText data={content.text} />
                </article>
            </main>
            <aside className={CSS.aside}>
                <FeaturedPosts ignore={data.storyblokEntry.full_slug} darker blok={{automaticLatestPosts: 'blog-posts', limitPostAmount: 6}} title="Latest blog posts" />
            </aside>
        </Layout>
    )
}

export const Head = ({ data }) => {
    const content = data.storyblokEntry.content?.title ? data.storyblokEntry.content : JSON.parse(data.storyblokEntry.content);
    return (
        <Seo
            title={content?.title + ' – WorkED'}
            description={content?.description}
            url={`/${data.storyblokEntry.full_slug}`}
            type="article"
        />
    )
}

export const query = graphql`
  query ($full_slug: String!, $header_full_slug: String!, $footer_full_slug: String!) {
    storyblokEntry(full_slug: { eq: $full_slug }) {
        full_slug
        content
        id
        uuid
        internalId
    }
    header: storyblokEntry(full_slug: { eq: $header_full_slug }) {
        content
        id
        uuid
        internalId
    }
    footer: storyblokEntry(full_slug: { eq: $footer_full_slug }) {
        content
        id
        uuid
        internalId
    }
  }
`

export default BlogPost